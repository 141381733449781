// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useTranslation } from "react-i18next";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";
import { Gallery, Item } from "react-photoswipe-gallery";

// style imports
import "../index.scss";
import "./pousada.scss";
import "photoswipe/dist/photoswipe.css";
import "../../styles/photoswipe.scss";

// svg imports

// components imports
import Layout from "../../components/Layout/Layout.js";
import Seo from "../../components/Seo/Seo.js";

// page
export default function IndexPage({ data }) {
	const { t } = useTranslation("pousada");
	const { language } = useI18next();

	const parallaxSettings = {
		speed: 20,
		easing: "easeInOut",
		className: "parallaxSection",
		onEnter: (element) => element.style,
	};

	// const images = data.images.edges;

	const facilitiesList = [
		t("wifi"),
		t("A/C"),
		t("breakfast"),
		t("lunch"),
		t("dinner"),
		t("a la carte menu"),
		t("private events"),
		t("exclusive hire"),
		t("room service"),
		t("laundry service"),
		t("private parking"),
		t("safe"),
		t("wellness"),
		t("concierge service"),
		t("car / bike rental"),
		t("store"),
		t("beach towel service"),
		t("marbella guide"),
	];

	const contactList = [
		// {
		// 	name: t("bookings here"),
		// 	text: t("bookings page"),
		// 	link: "",
		// 	internalLink: "/boutique/reserva",
		// },
		{
			name: t("phone"),
			text: "+34 691 22 34 67",
			link: "tel:+34691223467",
		},
		{
			name: t("whatsapp"),
			text: "+34 691 22 34 67",
			link:
				"https://wa.me/34691223467?text=" +
				t("Hello, I would like to book a stay at Bossa Pousada"),
		},
		{
			name: t("e-mail"),
			text: "rentals@bossaestepona.com",
			link: "mailto:rentals@bossaestepona.com",
		},
		{
			name: t("instagram"),
			text: "@bossaestepona",
			link: "https://www.instagram.com/bossaestepona/",
		},
		{
			name: t("address"),
			text: "Av. Parque Selwo, Calle Lima Local 5, Estepona",
			link: "https://goo.gl/maps/u86vRQLU4C5rSsP59",
		},
	];

	return (
		<Layout local="pousada">
			<Seo lang={language} title="Pousada" />
			<div className="bodyContainer">
				{/* <p className="bold">Bold text</p> */}

				<section>
					<Parallax {...parallaxSettings}>
						<p>
							<Trans ns="pousada">
								Intimate 8 apartments, the perfect getaway lulled by the
								Mediterranean Sea
							</Trans>
							.<br />
							<br />
							<span className="bold">
								<Trans ns="pousada">Brazil, from the heart</Trans>.
							</span>
							<br />
							<br />
							<Trans ns="pousada">
								Brazilian art’s, lovely details, in house café and an exclusive
								concierge service. A place for all those who not only want to
								enjoy luxury in their own apartment, but are also looking for
								peace and authenticity
							</Trans>
							.
						</p>
					</Parallax>

					<StaticImage
						src={`../../images/pousada/landing.jpg`}
						alt={t("a warmth, cozy apartment interior")}
						className={"landingBgImage"}
					/>
				</section>

				<section id="photos">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans ns="pousada">photo gallery</Trans>.
						</h2>
						<div className="imageGallery">
							<Gallery options={{ showHideAnimationType: "fade" }}>
								{data?.images?.nodes?.map((image, i) => (
									<Item
										original={image?.childImageSharp.original.src}
										originalSrcset={image?.childImageSharp.fluid.srcSet}
										width={image?.childImageSharp.original.width}
										height={image?.childImageSharp.original.height}
										key={i}
									>
										{({ ref, open }) => (
											<button ref={ref} onClick={open} aria-label="Zoom">
												<GatsbyImage
													image={image.childImageSharp.gatsbyImageData}
													alt=""
												/>
											</button>
										)}
									</Item>
								))}
							</Gallery>
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/pousada/photos.jpg`}
						alt={t(
							"a warmth lounge corner, two relaxing chairs and a small table with magazines"
						)}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="amenities">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans ns="pousada">facilities</Trans>.
						</h2>

						<div className="servicesFlex bold facilitiesFlex">
							{facilitiesList.map((facility) => {
								return <div key={facility}>{facility}.</div>;
							})}
						</div>

						<div>
							<p>
								<br />
								<Trans ns="pousada">No pets allowed</Trans>.
							</p>
							<p>
								<Trans ns="pousada">
									Café / Restaurant, Delicatessen Shop, free WiFi and concierge
									service
								</Trans>
								.
							</p>
							<p>
								<Trans ns="pousada">
									In the apartments: TV, air conditioning, free wifi, iron and
									ironing board, hairdryer, fully equipped kitchen;
									Fridge-freezers, stovetops, microwaves, sinks, kettles, coffee
									machines and toasters
								</Trans>
								.
							</p>
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/pousada/facilities.jpg`}
						alt={t(
							"a top view of some delicious looking food, a toast with poached eggs and smoked salmon and a fruit bowl"
						)}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="contact">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans ns="pousada">booking</Trans>.
						</h2>

						<div className="contactList">
							<p>
								<Trans ns="pousada">
									Please reach out to discuss property availability and booking
									your stay with us
								</Trans>
								.
							</p>
							{contactList.map((detail) => {
								return (
									<div key={detail.name}>
										<span className="light">{detail.name}. </span>
										<div>
											{detail.internalLink ? (
												<Link to={detail.internalLink}>{detail.text}</Link>
											) : (
												<a href={detail.link}>{detail.text}</a>
											)}
											.
										</div>
									</div>
								);
							})}
						</div>

						<div>
							<br />
							<br />
							<p className="bold">
								<Trans ns="pousada">join our jorney, live the BOSSA</Trans>
							</p>
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/pousada/booking.jpg`}
						alt={t(
							"a warmth lounge corner, two relaxing chairs and a small table with magazines"
						)}
						className={"landingStripeImage"}
					/>
				</section>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		images: allFile(
			filter: {
				sourceInstanceName: { eq: "images" }
				relativePath: { regex: "/^pousada/photoGallery//" }
			}
			sort: { fields: name }
		) {
			nodes {
				childImageSharp {
					fluid {
						srcSet
					}
					original {
						src
						height
						width
					}
					gatsbyImageData
				}
			}
		}
	}
`;
